<template>
  <div class="main">
    <!-- welcome -->
    <a-card v-if="!plan">
      <a-result
        status="500"
        :title="$t('msg.troubleshoot.title')"
        :sub-title="$t('msg.troubleshoot.subtitle')">
        <template #icon>
          <a-icon type="tool" />
        </template>
        <template #extra>
          <a-button
            key="console"
            type="primary"
            size="large"
            :loading="loading"
            @click="run">
            {{ $t('msg.troubleshoot.run') }}
          </a-button>
        </template>
      </a-result>
    </a-card>
    <!-- welcome -->
    <!-- plan -->
    <div v-if="plan">
      <a-card>
      <template #title>
        <h3>{{ $t('msg.troubleshoot.title') }}</h3>
        <div>
          {{ plan.date | calendar | capital }}
        </div>
      </template>
      <template #extra>
        <a-button
          icon="redo"
          type="secondary"
          :loading="loading"
          @click="run">
          {{ $t('msg.troubleshoot.retry') }}
        </a-button>
      </template>
      <a-collapse
        :active-key="failures"
        expand-icon-position="right">
        <!-- suite -->
        <a-collapse-panel
          v-for="suite in plan.suites"
          :key="suite.title">
          <!-- header -->
          <template #header>
            <div class="py-2 font-size-18">
              <span slot="avatar" class="mr-2">
                <a-avatar
                  icon="check"
                  class="bg-success"
                  v-if="suite.status === 'OK'" />
                <a-avatar
                  icon="warning"
                  class="bg-warning"
                  v-else-if="suite.status === 'FAILED' && suite.level === 'WARN'" />
                <a-avatar
                  icon="close"
                  class="bg-danger"
                  v-else-if="suite.status === 'FAILED'" />
                <a-avatar
                  icon="pause"
                  class="bg-default"
                  v-else-if="suite.status === 'SKIPPED'" />
                <a-avatar
                  icon="exclamation"
                  class="bg-danger"
                  v-else-if="suite.status === 'ABORTED'" />
              </span>
              {{ suite.title }}
            </div>
          </template>
          <!-- /header -->
          <!-- tests -->
          <a-list item-layout="horizontal" :data-source="suite.tests">
            <a-list-item slot="renderItem" slot-scope="test">
              <a-list-item-meta
                :title="test.title"
                :description="test.subtitle">
                <span slot="avatar">
                  <a-avatar
                    icon="check"
                    class="bg-success"
                    v-if="test.status === 'OK'" />
                  <a-avatar
                    icon="warning"
                    class="bg-warning"
                    v-else-if="test.status === 'FAILED' && test.level === 'WARN'" />
                  <a-avatar
                    icon="close"
                    class="bg-danger"
                    v-else-if="test.status === 'FAILED'" />
                  <a-avatar
                    icon="pause"
                    class="bg-default"
                    v-else-if="test.status === 'SKIPPED'" />
                  <a-avatar
                    icon="exclamation"
                    class="bg-danger"
                    v-else-if="test.status === 'ABORTED'" />
                </span>
              </a-list-item-meta>
              <template #actions>
                <a target="_blank"
                  :href="test.solution"
                  v-if="test.status === 'FAILED' && test.solution">
                  <a-button type="link">
                    {{ $t('msg.troubleshoot.fix') }}
                  </a-button>
                </a>
              </template>
            </a-list-item>
          </a-list>
          <!-- /tests -->
        </a-collapse-panel>
        <!-- /suite -->
      </a-collapse>
      </a-card>
    </div>
    <!-- /plan -->
  </div>
</template>
<script>
import lms from '@/api/lms'
export default {
  data: function () {
    return {
      plan: null,
      loading: false,
    }
  },
  computed: {
    failures() {
      return this.plan?.suites?.filter(s => s.status === 'FAILED' || s.status === 'ABORTED').map(s => s.title) ?? []
    },
  },
  methods: {
    run() {
      this.loading = true
      const request = { account: this.account }
      return lms.troubleshoot.run(request)
        .then(response => (this.plan = response.data))
        .finally(() => (this.loading = false))
    },
  },
}
</script>
